// extracted by mini-css-extract-plugin
export var hero = "http404-module--hero--2bs6L";
export var container = "http404-module--container--3JCZ3";
export var intro = "http404-module--intro--mULqh";
export var shapeFirst = "http404-module--shape-first--3mqNm";
export var shapeSecond = "http404-module--shape-second--3ZiD8";
export var shapeThird = "http404-module--shape-third--rWDhm";
export var responsiveWrapper = "http404-module--responsiveWrapper--3sJrQ";
export var play = "http404-module--play--2Kygv";
export var cover = "http404-module--cover--34LXw";
export var imageWrapper = "http404-module--imageWrapper--3e8co";