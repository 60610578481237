import React, { useEffect } from "react"
import { useStaticQuery, graphql, withPrefix } from "gatsby"

import PrivateRoute from "../components/app/privateRoute"
import { Router,Route } from "@reach/router"
import ProductsPage from "./modulesList"
import ProductSingle from "./product-item"
import loadLessonsInLocalStorage from '../services/loadLessonsInLocalStorage';
import NotFoundPage from "./404";

const Modules = (props) => {
   
  useEffect(() => {
    loadLessonsInLocalStorage();
  },[])

    const { wpPage: { modulesMetaData } } = useStaticQuery(graphql`
    query {
      wpPage(slug: {eq: "modules"}) {
        id
        modulesMetaData
      }
    }
  `)

    return (
        <Router basepath={withPrefix("/modules")}>
            <PrivateRoute checkLogin={false} path={"/"} component={ProductsPage} jumpTo={props.location != null && props.location.state != null && props.location.state.jumpTo != undefined ? props.location.state.jumpTo : ""} />
            <PrivateRoute path={"/:id"}  component={ (props) => (
              <ProductSingle {...props} />
              )} />
        </Router>
    )
}

export default Modules
