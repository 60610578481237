import React, {useEffect, useState} from "react"
import Layout from "../components/layout"
import Post from "../components/posts/post"
import PostFull from "../components/posts/post-full"
import PostsGroup from "../components/posts/posts-group"

import products from "../data/products"

import * as pageStyles from "../scss/productItemPage.module.scss"
import * as postsGroupStyles from "../components/posts/postsGroup.module.scss"
import Pagination from "../components/pagination"

const ProductsPage = (props) => {

  const [singleLesson, setSingleLesson] = useState('');

  useEffect(() => {
    function mockLoading () {
    let lessonId = props.location.state.lessonId;
    let lessonsString= localStorage.getItem('lessons');
    let lessonsArray = JSON.parse(lessonsString);
    let lesson = lessonsArray.filter(element=>element.id == lessonId);
    setSingleLesson(lesson);
}
  mockLoading()
}, []);


  return (
    <>
      <Layout>
      {singleLesson != '' ?   
        <div className={`container ${pageStyles.container}`}>
          <Pagination style={{ marginTop: "40px" }} />
          {/* <PostFull item={products[1]} /> */}
          <PostFull item={singleLesson}/>
          <PostsGroup title="Nächste Module">
            <div className={postsGroupStyles.threeItems}>
              <Post type="small" item={products[2]} />
              <Post type="small" item={products[3]} />
              <Post type="small" item={products[4]} />
            </div>
          </PostsGroup>
        </div>
        : null}
      </Layout>
    </>
  )
}

export default ProductsPage
