import * as React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { useEffect, useRef, useState } from "react"

import Link from "gatsby-link";


import * as styles from "../scss/http404.module.scss"
import * as btnStyles from "../components/buttons/buttons.module.scss"
import * as indexStyles from "../scss/indexPage.module.scss"



const NotFoundPage = () => {
    const shapeFirst = useRef(null)
    const shapeSecond = useRef(null)
    const shapeThird = useRef(null)
    const [isMount, setMount] = useState(false);

    useEffect(() => {

        const shapeFistOffsetTop = shapeFirst?.current?.offsetTop;

        const shapeSecondOffsetTop = shapeSecond?.current?.offsetTop;

        window.addEventListener("scroll", () => {
            if (shapeFirst.current) {
                shapeFirst.current.style.top =
                    shapeFistOffsetTop + window.scrollY / 3 + "px"
                shapeSecond.current.style.top =
                    shapeSecondOffsetTop - window.scrollY / 3 + "px"
            } else {
                window.removeEventListener("scroll", () => { })
            }
        })
    }, [])

    useEffect(() => {
        setMount(true);
    }, [])

    if (!isMount) {
        return (
            <div></div>
        )
    }
    return (
        <Layout>

            <SEO
                title="404: Not found"
                description="Dein 7-Tage Detox Kurs - Alles was du brauchst, um optimal zu entgiften! Mit über 45 Videoanleitungen in HD, 7 strukturierten Tagesplänen, einem Detox-Rezeptbuch und alle notwendigen Unterlagen für eine erfolgreiche Detox-Woche."
                keywords="Detox, Abnehmen, Fasten, Online, Kurs, Entgiften,Entschlacken"
                author="spectory"
            />
            <div className={styles.responsiveWrapper}>
                <div className={styles.hero}>
                    <div className={`clearfix container ${styles.container}`} style={{ margin: 0, padding: 0 }}>
                        <div className={styles.intro}>
                            <h1>
                                404 - Seite nicht gefunden
                            </h1>
                            <div style={{ marginTop: 20 }}>Die von dir gesuchte Seite konnte leider nicht gefunden werden.</div>
                            <div>Bitte gehe zur Startseite zurück und navigiere dich zu deinem gewünschten Thema.</div>
                            <div
                                className={`${btnStyles.buttonGroup} ${indexStyles.buttonGroup}`}
                            >
                                <Link style={{ marginLeft: 6 }} to="/" className={`${btnStyles.button} ${btnStyles.buttonBorder}`}>
                                    Startseite
                                </Link>
                            </div>
                        </div>
                        <div ref={shapeFirst} className={styles.shapeFirst}></div>
                        <div ref={shapeSecond} className={styles.shapeSecond}></div>
                        <div ref={shapeThird} className={styles.shapeThird}></div>
                    </div>
                </div>

                <div style={{ height: 40, backgroundColor: "#E4F8EF", width: "100%", position: "absolute", bottom: 0, left: 0, display: "block" }}></div>
            </div>
        </Layout>
    )
}

export default NotFoundPage
