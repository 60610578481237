import React, { useEffect } from "react";
import axios from 'axios';


const loadLessonsInLocalStorage = () => {
    let userToken = localStorage.getItem('user');
    if(userToken == null ) {
        return false;
    }
 
    let data = JSON.stringify({
        "token": userToken
    });

    let config = {
        method: 'post',
        url: 'https://detoxme.at/wp/wp-json/siegfried/v1/lessons',
        headers: {
            'Content-Type': 'application/json'
        },
        data: data
    };
    axios(config)
        .then(
            (response) => {
                const { lessons } = response.data;
                const lessonsString = JSON.stringify(lessons);
                if (response.data.c == 200) {
                        localStorage.setItem("lessons", lessonsString);
                }
            }
        )
        .catch(function (error) {
            console.log(error);
        });
}

export default loadLessonsInLocalStorage;